<template>
  <div class="statistics">
    <div
      class="statistics-item"
      v-for="(item, idx) in statisticData"
      :key="idx"
    >
      <span class="statistics-item-label">{{item.label}}</span>
      <span class="statistics-item-value">{{valueItem[item.value]}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    statisticData: {
      default: function() {
        return []
      },
      type: Array
    },
    valueItem: {
      default: function() {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {}
  },
  // watch: {
  //   "valueItem": function(){
  //     console.log(this.valueItem)
  //   }
  // },
  methods: {},
  mounted() {}
}
</script>

<style lang='sass' scoped>
.statistics
  width: 100%
  height: 241px
  background: url('../../../assets/images/statistics-bg.png') no-repeat bottom / cover
  &-item
    display: inline-block
    width: 100%
    height: 60px
    padding-top: 40px
    padding-left: 40px
    &-label
      font-size: 14px
      color: rgba(38,38,38,1)
    &-value
      float: right
      margin-right: 40px
      font-size: 30px
      color: rgba(38,38,38,1)
</style>
