<template>
  <div class="chart-line">
    <div class="line" ref="line"></div>
  </div>
</template>

<script>
  import echarts from 'echarts'
  // const seriesItem =
  const option = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#ffffff',
      textStyle: {
        color: '#666666'
      },
      extraCssText: 'box-shadow:0px 2px 6px 0px rgba(0, 0, 0, 0.14);box-sizing: border-box;padding: 14px',
      axisPointer: {
        lineStyle: {
          color: '#D9D9D9'
        }
      },
      formatter: function (params) {
        return params[0].data.tooltips
      },
      // formatter: '{b}'
    },
    grid: {
      top: '8%',
      left: '2%',
      right: '3%',
      bottom: '3%',
      height: '85%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
      axisLine: {
        lineStyle: {
          color: "#999"
        },
      },
      axisTick: {
        alignWithLabel: true
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: '#DDD'
        }
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: "#333"
        },
      },
      nameTextStyle: {
        color: "#999"
      },
      splitArea: {
        show: false
      }
    },
    series: [{
      name: null,
      type: 'line',
      emptyCircle: 'circle',
      data: [],
      lineStyle: {
        normal: {
          width: 3,
          color: 'rgba(64, 169, 255, 1)',
          shadowColor: 'rgba(64, 169, 255, .4)',
          shadowBlur: 3,
          shadowOffsetY: 10
        }
      },
      itemStyle: {
        normal: {
          color: 'rgba(64, 169, 255, 1)',
          borderColor: '#ffffff',
          borderWidth: 3
        }
      },
      symbol: 'circle',
      symbolSize: 8,
      showSymbol: false,
      smooth: true,
      smoothMonotone: 'none'
    }]
  };
  export default {
    props: {
      lineData: {
        default: function () {
          return []
        },
        type: Array
      }
    },
    data () {
      return {}
    },
    watch: {
      "lineData": function(){
        this.drawLine()
      }
    },
    methods: {
      drawLine(){
        let lineOption = option
        // lineOption.series[0].data = this.lineData[0].data
        // lineOption.xAxis.data = this.lineData[0].xData
        lineOption.series[0].data = this.lineData
        lineOption.xAxis.data = this.lineData.map(e => e.label)
        let line = echarts.init(this.$refs.line)
        line.setOption(lineOption)
        window.addEventListener('resize', function () {
          line.resize()
        })
      }
    },
    mounted () {
      this.drawLine()
    }
  }
</script>

<style lang='sass' scoped>
  .chart-line
    width: 100%
    height: 400px
    .line
      width: 100%
      height: 100%
</style>
