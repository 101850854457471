<template>
  <div>
    <single-chart-line :lineData="lineData"/>
  </div>
</template>

<script>
  export default {
    props: {
      lineData: {
        default: function(){
          return []
        },
        type: Array
      }
    },
    data () {
      return {
        // name - data - normal.color - normal.shadowColor - itemStyle.normal.color
      }
    },
    methods: {
    },
    mounted () {
    }
  }
</script>

<style lang='sass' scoped>

</style>
