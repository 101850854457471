<template>
  <div style="overflow: auto;">
    <ayl-berad-nav v-if="scope === 'SANITATION'" :nav="nav"></ayl-berad-nav>
    <div class="p24px main">
      <!-- 1.到期提醒+日均里程-报警总数 -->
      <div
        class="main-item"
        v-for="(item, idx) in topItem1"
        :key="idx"
      >
        <div @click="router(item.index)">
          <div class="main-item-value">{{valueItem1[item.value]}}</div>
          <div class="main-item-label">{{item.label}}</div>
          <span class="remindCol">
            <img
              class="remind_img"
              :src="item.img"
              alt=""
            >
          </span>
        </div>

      </div>

      <!-- 总览  -->
      <div style="position: relative">
        <div style="padding: 20px 0 15px 0">
          <span class="homeIcon">
            <img
              src="../../assets/images/board_6.png"
              alt=""
            >
          </span>
          <span class="homeTitle">总览</span>
        </div>
        <div class="topLine">
          <div class="topLine-right">
            <span
              @click="getTopItemData(7)"
              :class="{'tabs-active': tabsTimeIndex === 1}"
            >最近7天</span>
            <span
              @click="getTopItemData(15)"
              :class="{'tabs-active': tabsTimeIndex === 2}"
            >最近15天</span>
          </div>
        </div>
        <div class="main-overview">
          <div class="main-overview-value">
            <p class="main-overview-value-value">车辆总数</p>
            <p class="main-overview-value-label">{{ valueItem.carCount }}</p>
          </div>
          <div class="main-overview-hr"></div>
          <div class="main-overview-value">
            <p class="main-overview-value-value">日均里程(km)</p>
            <p class="main-overview-value-label1">{{ valueItem.averageMileage }}</p>
          </div>
          <div class="main-overview-hr1"></div>
          <div class="main-overview-value">
            <p class="main-overview-value-value">报警总数</p>
            <p class="main-overview-value-label2">{{ valueItem.totalCount }}</p>
          </div>
        </div>
      </div>

      <!-- 2.报警分布 -->
      <div class="icon">
        <span class="homeIcon">
          <img
            src="../../assets/images/board_7.png"
            alt=""
          >
        </span>
        <span class="homeTitle">车辆状态</span>
      </div>
      <div
        class="icon"
        style="width: calc((50% - 10px));margin-left: 10px"
      >
        <span class="homeIcon">
          <img
            src="../../assets/images/board_8.png"
            alt=""
          >
        </span>
        <span class="homeTitle">报警分布</span>
      </div>
      <div
        class="main-panel"
        style="float:left;width: 50%"
      >
        <div style="padding-bottom: 10px">
          <div
            slot="panel-content"
            style="height:444px;width:100%; "
          >
            <distribute :pieData="pieceLists" />
            <!-- <distribute :boardData="boardData"></distribute> -->
          </div>
        </div>

      </div>
      <div
        class="main-panel"
        style="width: 50%;position: relative;"
      >
        <div class="alarm-type">
          <div
            class="adas"
            @click="clickAlarm(1)"
            :class="{'tabs-active': tabsAlarm === 1}"
          >ADAS报警</div>
          <div
            class="dms"
            @click="clickAlarm(2)"
            :class="{'tabs-active': tabsAlarm === 2}"
          >DMS报警</div>
        </div>
        <div
          slot="panel-content"
          style="height: 444px"
        >
          <div style="width:100%; height:100%;padding-left: 10px">
            <chart-index
              v-if="tabsAlarm === 1"
              :boardData="boardData"
            ></chart-index>
            <chart-index
              v-if="tabsAlarm === 2"
              :boardData="dmsData"
            ></chart-index>
          </div>
        </div>
      </div>
      <!-- <ayl-panel :panel = "panel[0]" class="main-panel-distribute">
                    <div slot="panel-content" style="height: 241px">
                        <div style="width:500px; height:100%; margin:0 auto;">
                            <chart-board :boardData="boardData"></chart-board>
                        </div>
                    </div>
                </ayl-panel> -->
      <!-- 报警统计 -->
      <!-- <ayl-panel :panel = "panel[1]" class="main-panel-statistics">
                    <div slot="panel-content" style="height: 241px">
                        <statistics :statisticData="statisticData" :valueItem="valueItem"></statistics>
                    </div>
                </ayl-panel> -->

      <!-- 3.报警数/百公里 -->
      <div
        class="icon"
        style="width: 100%"
      >
        <span class="homeIcon">
          <img
            src="../../assets/images/board_9.png"
            alt=""
          >
        </span>
        <span class="homeTitle">报警数/百公里</span>
      </div>
      <div
        class="main-panel"
        style="background: white;padding-top: 20px"
      >
        <div
          slot="panel-content"
          style="height: 434px"
        >
          <div class="echarts-top">
            <span
              @click="clickAll(1)"
              :class="{'tabs-active': tabsIndex === 1}"
            >全部</span>
            <span
              @click="clickAll(2)"
              :class="{'tabs-active': tabsIndex === 2}"
            >ADAS</span>
            <span
              @click="clickAll(3)"
              :class="{'tabs-active': tabsIndex === 3}"
            >DMS</span>
            <span
              @click="clickAll(4)"
              :class="{'tabs-active': tabsIndex === 4}"
            >普通</span>
          </div>
          <!-- 全部 -->
          <alarm-total
            v-if="tabsIndex===1"
            :lineData="lineDataAll"
          />
          <!-- ADAS -->
          <!-- <a-alarm-total v-if="tabsIndex===2" :lineData="lineDataADAS" /> -->
          <!-- DMS -->
          <!-- <d-alarm-total v-if="tabsIndex===3" :lineData="lineDataDSM" /> -->
          <!-- 普通 -->
          <!-- <analysis-line v-if="tabsIndex===4" :lineData="lineData"></analysis-line> -->
          <div
            v-for="(item, idx) in linedatas"
            :key="idx"
          >
            <analysis-line
              v-if="tabsIndex===idx+2"
              :lineData="item"
            ></analysis-line>
          </div>
        </div>
      </div>
      <!-- 4.车辆报警排名(AI报警数/百公里)-司机得分排名 -->
      <div class="icon">
        <span class="homeIcon">
          <img
            src="../../assets/images/home_1.png"
            alt=""
          >
        </span>
        <span class="homeTitle">车辆报警排名(AI报警数/百公里)</span>
      </div>
      <div
        class="icon"
        style="width: calc((50% - 10px));margin-left: 10px"
      >
        <span class="homeIcon">
          <img
            src="../../assets/images/board_0.png"
            alt=""
          >
        </span>
        <span class="homeTitle">司机得分排名</span>
      </div>
      <div
        class="main-panel-ranking"
        style="background: white;"
      >
        <div
          slot="panel-content"
          style="height: 361px"
        >
          <car-ranking :carData="carData"></car-ranking>
        </div>
      </div>
      <div
        class="main-panel-drive"
        style="background: white;"
      >
        <div
          slot="panel-content"
          style="height: 361px"
        >
          <funnel-plot :driveData="driveData"></funnel-plot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Statistics from './components/Statistics'
import AlarmTotal from './components/AlarmTotal'
import CarRanking from './components/CarRanking'
import DriveRanking from './components/DriveRanking'
import Distribute from '../home/components/Distribute'
import AnalysisLine from './components/AnalysisLine'
import { async } from 'q'
export default {
  components: {
    Statistics,
    AlarmTotal,
    CarRanking,
    DriveRanking,
    AnalysisLine,
    Distribute
  },
  data() {
    return {
      scope: sessionStorage.getItem('scope', ''),
      nav: [
          {name: '首页'}
        ],
      topItem1: [
        {
          label: '保险到期',
          value: 'insuranceExpires',
          img: require('../../assets/images/home_4.png'),
          index: 1
        },
        {
          label: '运输证到期',
          value: 'transportPermit',
          img: require('../../assets/images/home_5.png'),
          index: 2
        },
        {
          label: '行驶证到期',
          value: 'drivingLicense',
          img: require('../../assets/images/home_6.png'),
          index: 3
        },
        {
          label: 'SIM卡到期',
          value: 'simCard',
          img: require('../../assets/images/home_7.png'),
          index: 4
        }
      ],
      statisticData: [
        {
          label: '普通报警数',
          value: 'commonAlarmCount'
        },
        {
          label: 'ADAS报警数',
          value: 'ADASAlarmCount'
        },
        {
          label: 'DMS报警数',
          value: 'DSMAlarmCount'
        }
      ],
      valueItem: {
        carCount: 0,
        averageMileage: 0,
        totalCount: 0,
        AIAlarmCount: null,
        commonAlarmCount: null,
        ADASAlarmCount: null,
        DSMAlarmCount: null
      },
      valueItem1: {
        insuranceExpires: 0,
        transportPermit: 0,
        drivingLicense: 0,
        simCard: 0
      },
      panel: [
        {
          title: '报警分布',
          icon: require('../../assets/images/board_1.png'),
          isShowLine: true
        },
        {
          title: '报警统计',
          icon: require('../../assets/images/board_2.png'),
          isShowLine: true
        },
        {
          title: '报警数/百公里',
          icon: require('../../assets/images/board_3.png'),
          isShowLine: false
        },
        {
          title: '车辆报警排名(AI报警数/百公里)',
          icon: require('../../assets/images/board_4.png'),
          isShowLine: true
        },
        {
          title: '司机得分排名',
          icon: require('../../assets/images/board_5.png'),
          isShowLine: true
        }
      ],
      boardData: [],
      dmsData: [],
      carData: [],
      driveData: [],
      tabsIndex: 1,
      tabsAlarm: 1,
      tabsTimeIndex: 1,
      lineDataAll: [],
      lineDataNormal: [],
      lineDataADAS: [],
      lineDataDSM: [],
      linedatas: [],
      // loading: true,
      pieceLists: {}
    }
  },
  methods: {
    router(item) {
      switch (item) {
        case 1:
          this.$router.push({
            path: '/operation/insuranceInfo',
            query: {
              option: '1'
            }
          })
          break
        case 2:
          this.$router.push({
            path: '/operation/transportationLicenseInfo',
            query: {
              option: '1'
            }
          })
          break
        case 3:
          this.$router.push({
            path: '/operation/drivingLicenseInfo',
            query: {
              option: '1'
            }
          })
          break
        case 4:
          this.$router.push({
            path: '/operation/simManage',
            query: {
              option: '1'
            }
          })
          break
        default:
          return
      }
    },
    clickAll(idx) {
      this.tabsIndex = idx
    },
    clickAlarm(idx) {
      this.tabsAlarm = idx
    },
    async getTopItemData(day) {
      if (day === 7) {
        this.tabsTimeIndex = 1
        this.getAllData(7)
      } else {
        this.tabsTimeIndex = 2
        this.getAllData(15)
      }
      this.valueItem = await this.$api.getTopStaticData({ dayCount: day })
    },
    /**
     * 获取所有接口数据
     */
    getAllData(day) {
      // this.loading = true
      /** 获取顶部统计信息 */
      this.$api
        .getAlarmDistributionData({ dayCount: day })
        .then(res => {
          //this.boardData = res.pieList
        })
        .catch(e => {
          console.log(e)
        })
      /** 首页报警分布 DMS ADAS */
      this.$api
        .DataAlarmDistributionData({ dayCount: day })
        .then(res => {
          this.boardData = res.adas
          this.dmsData = res.dms
        })
        .catch(e => {
          console.log(e)
        })
      /**  获取报警分布数据 */
      this.$api
        .getTopStaticData({ dayCount: day })
        .then(res => {
          this.valueItem = res
        })
        .catch(e => {
          console.log(e)
        })
      /** 报警数/百公里 折线图数据 */
      this.$api
        .getAlarmLineChartData({ dayCount: day })
        .then(res => {
          this.lineDataAll = res.LineData
          let arr_1 = []
          arr_1 = this.lineDataAll[0].data.map((e, i) => {
            return {
              label: this.lineDataAll[0].xData[i],
              value: e,
              tooltips: res.subClassNoteData[i].adasOrderSubClassMap
            }
          })
          let arr_2 = []
          arr_2 = this.lineDataAll[1].data.map((e, i) => {
            return {
              label: this.lineDataAll[1].xData[i],
              value: e,
              tooltips: res.subClassNoteData[i].dsmOrderSubClassMap
            }
          })
          let arr_3 = []
          arr_3 = this.lineDataAll[2].data.map((e, i) => {
            return {
              label: this.lineDataAll[2].xData[i],
              value: e,
              tooltips: res.subClassNoteData[i].commonOrderSubClassMap
            }
          })
          this.linedatas = [arr_1, arr_2, arr_3]
          // this.loading = false
        })
        .catch(e => {
          console.log(e)
        })
      /**  车辆报警排名 */
      this.$api
        .getAlarmRanking({ sequence: 0, dayCount: day })
        .then(res => {
          this.carData = res
        })
        .catch(e => {
          // console.log(e)
        })
      /**  司机得分排名 */
      let vm = this
      this.$api
        .getDriverScoreRanking({ dayCount: day })
        .then(res => {
          let value = []
          let driveData = (vm.driveData = res.map((e, i) => {
            return {
              label: e.name,
              score: e.score,
              value: 45
            }
          }))
          let size = vm.driveData.length
          //console.log('vm.driveData: ' + size + '条：' + JSON.stringify(vm.driveData))
          // vm.driveData = [{"label":"李健","score":108}]
          switch (size) {
            case 1:
              vm.driveData[0].value = 45
              break
            case 2:
              value = [60, 30]
              vm.driveData[0].value = 60
              vm.driveData[1].value = 30
              break
            case 3:
              value = [60, 40, 20]
              for (let i = 0; i < 3; i++) {
                vm.driveData[i].value = value[i]
              }
              break
            case 4:
              value = [60, 45, 30, 15]
              for (let i = 0; i < 4; i++) {
                vm.driveData[i].value = value[i]
              }
              break
            case 5:
              value = [65, 52, 39, 26, 13]
              for (let i = 0; i < 5; i++) {
                vm.driveData[i].value = value[i]
              }
              break
            default:
              break
          }
        })
        .catch(e => {
          //console.log(e)
        })
    }
  },
  async mounted() {
    // if(sessionStorage.getItem('loginData')){
      this.getAllData(7)
      //获取今日新增
      let remindData = await this.$api.ExpireReminderData({})
      this.valueItem1.insuranceExpires = remindData.insuranceCount
      this.valueItem1.transportPermit = remindData.transportLicenseCount
      this.valueItem1.drivingLicense = remindData.drivingLicenseCount
      this.valueItem1.simCard = remindData.simCount
      //获取不同状态的车辆信息
      let boardData = await this.$api.CarStatusCount({})
      //console.log(boardData)
      this.pieceLists = boardData.boardData[0]
    // }
    
  }
}
</script>

<style lang='sass' scoped>
.topLine
  font-family: Microsoft YaHei
  width: 100%
  &-left
  &-right
    position: absolute
    right: 24px
    top: 18px
    span
      display: inline-block
      width: 95px
      height: 32px
      border: 1px solid #D9D9D9
      line-height: 32px
      text-align: center
      cursor: pointer
      transition: border-color .35s
    &:hover
      border-color: $blue
    &:nth-child(1)
      border-radius: 4px 0 0 4px
    &:nth-child(2)
      border-radius: 0 4px 4px 0
    .tabs-active
        border-color: rgba(5, 117, 230, 1)
        color: rgba(5, 117, 230, 1)
.main
  &-panel
    display: inline-block
    width: 100%
    margin-top: 24px
    &-ranking
        float: left
        margin-top: 24px
        width: calc( (100% - 24px)  / 2)
    &-drive
        float: left
        margin-left: 24px
        margin-top: 24px
        width: calc( (100% - 24px)  / 2)
    &-distribute
        float: left
        width: calc( ((100% - 12px)  / 3) * 2)
    &-statistics
        float: left
        margin-left: 24px
        margin-right: 0px
        width: calc( (100% - 48px)  / 4)
  .homeIcon
    width: 27px
    height: 27px
    display: inline-block
    vertical-align: middle

    img
      width: 27px
      height: 27px

  .homeTitle
    font-size: 16px
    font-weight: 400
    line-height: 27px
    color: rgba(38,38,38,1)
    display: inline-block
    vertical-align: middle
    margin-left: 5px
    font-family: Microsoft YaHei
  &-overview
    width: 100%
    height: 148px
    position: relative
    background: rgba(255,255,255,1)
    box-shadow: 1px 0px 6px 0px rgba(5,117,230,0.1)
    border-radius: 6px
    &-value
        width: 33%
        height: 148px
        padding: 40px 0
        text-align: center
        display: inline-block
        vertical-align: middle
        &-value
            font-size: 14px
            font-family: Microsoft YaHei
            font-weight: 400
            color: rgba(102,102,102,1) !important
            padding-bottom: 29px
        &-label
            font-size: 30px
            font-family: Microsoft YaHei
            font-weight: bold
            color: rgba(5,117,230,1)
        &-label1
            font-size: 30px
            font-family: Microsoft YaHei
            font-weight: bold
            color: rgba(19,194,115,1)
        &-label2
            font-size: 30px
            font-family: Microsoft YaHei
            font-weight: bold
            color: rgba(255,77,79,1)
    &-hr
        width: 1px
        height: 110px
        position: absolute
        left: 33%
        top: 19px
        background: rgba(232,232,232,1)
    &-hr1
        width: 1px
        height: 110px
        position: absolute
        left: 66%
        top: 19px
        background: rgba(232,232,232,1)

  &-item
    position: relative
    display: inline-block
    width: calc( (100% - 69px)  / 4)
    height: 120px
    line-height: 120px
    text-align: left center
    margin-right: 23px
    border-radius: 5px
    &:nth-child(1)
        background: url('../../assets/images/car-total-bg.png') no-repeat center / cover
    &:nth-child(2)
        background: url('../../assets/images/mileage-bg.png') no-repeat center / cover
    &:nth-child(3)
        background: url('../../assets/images/alarm-total-bg.png') no-repeat center / cover
    &:nth-child(4)
        background: url('../../assets/images/alarm-icard-bg.png') no-repeat center / cover
        margin-right: 0px
    &-value
      margin-top: 28px
      color: rgba(255,255,255,1)
      font-size: 48px
      line-height: 35px
      margin-left: 30px
      font-family: Arial
    &-label
      color: rgba(255,255,255,1)
      font-size: 16px
      line-height: 16px
      margin-left: 24px
      margin-top: 15px
      font-family: Microsoft YaHei
    .remindCol
        position: absolute
        top: 24px
        right: 30px
        // display: inline-block
        // vertical-align: middle
        .remind_img
            width: 72px
            height: 72px
.echarts
  &-top
    width: 380px
    margin: 0 auto
    span
      display: inline-block
      width: 95px
      height: 35px
      border: 1px solid #D9D9D9
      line-height: 35px
      text-align: center
      cursor: pointer
      transition: border-color .35s
    &:hover
    &:nth-child(1)
      border-radius: 4px 0 0 4px
    &:nth-child(2)
      border-radius: 0 4px 4px 0
    &:nth-child(3)
      border-radius: 0 4px 4px 0
    &:nth-child(4)
      border-radius: 4px 0 0 4px
    .tabs-active
        border-color: rgba(5, 117, 230, 1)
        color: rgba(5, 117, 230, 1)
/deep/ .main-panel
    margin-top: 15px
.main-panel
    border-radius: 5px
.icon
    padding: 20px 0 0 0
    width: 50%
    display: inline-block
.alarm-type
    width: 100%
    position: absolute
    z-index: 1
    padding-left: 10px
    .adas
        width: 50%
        height: 60px
        line-height: 60px
        display: inline-block
        vertical-align: middle
        text-align: center
        font-size: 16px
        color: #666666
        font-family: Microsoft YaHei
    .dms
        width: calc(50%)
        height: 60px
        line-height: 60px
        display: inline-block
        vertical-align: middle
        text-align: center
        font-size: 16px
        color: #666666
        font-family: Microsoft YaHei
    .tabs-active
        border-color: rgba(5, 117, 230, 1)
        color: rgba(5, 117, 230, 1)
        background: rgba(230,241,252,1)
</style>
