<template>
  <div class="pie">
    <div class="pie-title">
      <span v-if="pieData.title">{{this.pieData.title}}</span>
      <span v-if="pieData.company">({{this.pieData.company}})</span>
    </div>
    <div class="pie-main" ref="pie"></div>
  </div>
</template>

<script>
  import echarts from 'echarts'
  const color = ["#FFA940", "#40A9FF", "#73D13D", "#9254DE", "#F759AB", "#fca4bb", "#f59a8f", "#fdb301", "#57e7ec", "#cf9ef1"]
  const option = {
    backgroundColor: "rgba(255,255,255,1)",
    color: color,
    legend: {
      orient: "horizontal",
      //top: "bottom",
      //right: "8%",
      bottom: "5%",
      data: [],
      itemWidth: 8,
      itemHeight: 8,
      icon: 'circle',
      itemGap: 18,
      formatter: function(name){
        //console.log(name)
        return '' +name
      },
      textStyle:{
        fontSize: 14
      },
    },
    tooltip: {
        trigger: 'item',
        formatter: "{b} ({d}%)",
        backgroundColor: '#ffffff',
        textStyle: {
          color: '#666666'
        },
        extraCssText: 'box-shadow:0px 2px 6px 0px rgba(0, 0, 0, 0.14);box-sizing: border-box;padding: 5px 40px 5px 14px',
        axisPointer: {
        type: 'shadow'
      }
    },
    series: [{
      type: 'pie',
      //饼图的扇区是否是顺时针排布
      clockwise: false,
      //最小的扇区角度（0 ~ 360）
      minAngle: 2,
      radius: ["40%", "65%"],
      center: ["50%", "50%"],
      avoidLabelOverlap: false,
      // 图形样式
      itemStyle: {
        normal: {
          borderColor: '#ffffff',
          borderWidth: 6,
        },
      },
      label: {
        normal: {
          show: false,
          position: 'center',
          formatter: '',
          rich: {
            text: {
              color: "transparent",
              fontSize: 14,
              align: 'center',
              verticalAlign: 'middle',
              padding: 8
            },
            value: {
              fontSize: 24,
              align: 'center',
              verticalAlign: 'middle',
            },
          }
        },
        emphasis: {
          show: true,
          textStyle: {
            fontSize: 20,
          }
        }
      },
      data: []
    }]
  };
  export default {
    props: {
      pieData: {
        default: function () {
          return {}
        },
        type: Object
      }
    },
    data () {
      return {
        timer: null,
        total: 0,
        labelList: []
      }
    },
    watch: {
      "pieData": function(){
        this.drawLPie()
        // console.log(this.pieData)
      }
    },
    methods: {
      drawLPie (){
        let pieOption = option
        let pie = echarts.init(this.$refs.pie)

        if(!this.pieData.pieceList)return
        pieOption.series[0].data = this.pieData.pieceList
        pieOption.legend.data = this.pieData.pieceList.map(e=>{
          return e.name
        })

        pie.setOption(pieOption)
        window.addEventListener('resize', function () {
          pie.resize()
        })
        return
        // 自动滚动
        pie.dispatchAction({type: 'highlight', seriesIndex: 0, dataIndex: this.total})
        this.timer = setInterval(() => {
          pie.dispatchAction({type: 'downplay', seriesIndex: 0, dataIndex: this.total})
          this.total++
          pie.dispatchAction({type: 'highlight', seriesIndex: 0, dataIndex: this.total})
          if (this.pieData.pieceList.length === this.total) {
            this.total = 0
            pie.dispatchAction({type: 'highlight', seriesIndex: 0, dataIndex: this.total})
          }
        }, 2000)
        setTimeout(()=> {
          // 添加鼠标移入事件
          pie.on('mouseover', params => {
            clearInterval(this.timer)
            for (let i = 0; i < this.pieData.pieceList.length; i++) {
              if (i !== params.dataIndex) {
                pie.dispatchAction({type: 'downplay', seriesIndex: 0, dataIndex: i})
              }
            }
            // pie.dispatchAction({
            //   type: "downplay",
            //   seriesIndex: 0,
            //   dataIndex: 1
            // });
            // pie.dispatchAction({
            //   type: "highlight",
            //   seriesIndex: 0,
            //   dataIndex: params.dataIndex
            // });
          });
          // 添加鼠标移出事件
          pie.on('mouseout', params => {
            this.total = params.dataIndex
            pie.dispatchAction({type: 'highlight', seriesIndex: 0, dataIndex: this.total})
            this.timer = setInterval(() => {
              pie.dispatchAction({type: 'downplay', seriesIndex: 0, dataIndex: this.total})
              this.total++
              pie.dispatchAction({type: 'highlight', seriesIndex: 0, dataIndex: this.total})
              if (this.pieData.pieceList.length === this.total) {
                this.total = 0
                pie.dispatchAction({type: 'highlight', seriesIndex: 0, dataIndex: this.total})
              }
            }, 2000)
          })
          // pie.on('mouseout', function() {
          //   pie.dispatchAction({
          //     type: 'highlight',
          //     seriesIndex: 0,
          //     dataIndex: 1
          //   });
          // });
          // pie.dispatchAction({
          //   type: 'highlight',
          //   seriesIndex: 0,
          //   dataIndex: 1
          // });
        }, 1000);
      }
    },
    mounted () {
      this.drawLPie()
      //console.log(this.pieData)
    }
  }
</script>

<style lang='sass' scoped>
.pie
  width: 100%
  height: 100%
  position: relative
  &-title
    position: absolute
    top: 0
    z-index: 88
    left: 50%
    transform: translateX(-50%)
    text-align: center
    span
      display: block
      &:nth-child(1)
        font-size: $fontX
        color: $black
        margin-bottom: 6px
  &-main
    width: 100%
    height: 100%
</style>
